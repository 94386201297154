import React from "react"


import SEO from "../components/seo"
import Layout from "../components/layout"
import Sectional from "../components/sectional"
import WebProjects from "../components/webprojects"
import DesignProjects from "../components/designprojects"

const IndexPage = ({ data }) => {
  return (
    <Layout>
        <div id="mover"></div>

      <SEO title="Web Developer &amp; Designer" />
      <Sectional sectionid="heading">
        <h1>
          Hi, I'm Tom.<br />
          I'm a Web Developer &amp; Graphic Designer from Los Angeles, CA.<br />I love creating, and I'm always finding new ways to combine my love of design and code.
        </h1>
      </Sectional>
      <Sectional sectionid="webprojects">
        <WebProjects />
      </Sectional>       
      <Sectional sectionid="designProjects">
        <DesignProjects />       
      </Sectional>
      <Sectional sectionid="contact">
        <h1 style={{textAlign: "left", paddingTop: "100px", color: "white"}}>Contact</h1>
        <h2 style={{textAlign: "left", fontSize: "0.35em", color: "white", lineHeight: "1em"}}>If you'd like to get me involved with a project, please reach out to my email me @ tdoyle.me</h2>
      </Sectional>       
      <Sectional sectionid="footer">
        <h1>The End</h1>
      </Sectional>       
    </Layout>
  )
}

export default IndexPage
