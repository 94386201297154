import React from "react"

const Sectional = ({ sectionid, children }) => {
    return (
        <section id={sectionid}>
            {children}
        </section>
    )
}

export default Sectional