import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Project from "./project"

const Webprojects = () => {
    const data = useStaticQuery(
        graphql`
        {
            web_projects: allMarkdownRemark(filter: {internal: {}, fields: {collection: {eq: "web_projects"}}}, sort: {fields: frontmatter___date, order: ASC}) {
              group(field: frontmatter___year) {
                edges {
                  node {
                    id
                    frontmatter {
                      client
                      role
                      title
                      year
                      month
                      linkout
                      technologies
                      date(formatString: "YYYY")
                      featuredImage {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    rawMarkdownBody
                  }
                }
                fieldValue
              }
            }
          }
        `
      )
    let totalIndex = 0  
    return (    
        <div>
            <h1 style={{textAlign: "left", paddingTop: "100px", paddingLeft: "150px"}}>Selected Web Projects</h1>
            {
                data.web_projects.group.reverse().map((item, index) => {
                    //console.log(data.web_projects)
                    return (
                        <ul className="projects">
                            <li className="divider" key={item.fieldValue +"-divider"}><h2>{ item.fieldValue }</h2></li>
                            {item.edges.reverse().map((item, index) => {
                              totalIndex++
                              return (
                                <Project content={item.node} itemIndex={totalIndex} />
                              );
                          })}        
                        </ul>
                        );
                })
            }
        </div>
    )
}
  
export default Webprojects   