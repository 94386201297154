import React from "react"
//import Img from "gatsby-image"
import ExternalLink from "../assets/external.svg"

const Project = ({ content, itemIndex }) => {
    const { frontmatter } = content
    return (
      <li key={frontmatter.title.replace(/\s/g,'')} className={(itemIndex % 2 === 0) ? "evenitem webproject" : "odditem webproject"}>
          <h2 className="title">
            <a href={frontmatter.linkout} target="_blank" rel="noreferrer">
              {frontmatter.title}
              <ExternalLink />
            </a>
          </h2>
          <h4 className="client">
            Created for {frontmatter.client} in {frontmatter.date}
          </h4>
          <p>{content.rawMarkdownBody}</p>
          <ul className="technologies">
            { frontmatter.technologies !== null && frontmatter.technologies.map((technology) => {
              //console.log(technology)
              return (
                <li>{technology}</li>
              )
            }) }
           </ul>
        
      </li>
    )
  }

export default Project