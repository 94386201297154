import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"

const Designprojects = () => {
    const data = useStaticQuery(
        graphql`
        {
            design_projects: allMarkdownRemark(filter: {internal: {}, fields: {collection: {eq: "design_projects"}}}, sort: {fields: frontmatter___date, order: DESC}) {
                edges {
                  node {
                    id
                    frontmatter {
                      client
                      role
                      title
                      date(formatString: "YYYY")
                      linkOut
                      featuredImage {
                        childImageSharp {
                          fixed(height: 500, quality: 100) {
                            ...GatsbyImageSharpFixed_withWebp
                          }
                          original {
                            src
                          }
                        }
                      }
                    }
                    rawMarkdownBody
                  }
                }
            }
          }
        `
      )
    return (    
        <div>
            <h1 style={{textAlign: "left", paddingTop: "100px", color: "#1236ff"}}>Selected Design Projects</h1>
            <h2 style={{textAlign: "left", fontSize: "0.2em", color: "#1236ff", lineHeight: "1em"}}>(Additional and animated work available upon <a href="#contact" style={{color: "#1236ff"}}>request</a>)</h2>
            <ul id="designprojectslist">
            {
                data.design_projects.edges.map((item, index) => {
                    let currentProject = item.node
                    let linkOut = ""
                    if (currentProject.frontmatter.linkOut !== null) 
                      linkOut = currentProject.frontmatter.linkOut
                    else 
                      linkOut = currentProject.frontmatter.featuredImage.childImageSharp.original.src
                    return (
                      <li key={item + index}>
                      <a href={linkOut}>
                        <div className="details">
                          <h1>{currentProject.frontmatter.title}</h1>
                          <h2>{currentProject.frontmatter.client}</h2>
                          <h4>{currentProject.frontmatter.date}</h4>
                        </div>
                        { item.node.frontmatter.featuredImage !== null && 
                            <Img fixed={item.node.frontmatter.featuredImage.childImageSharp.fixed} alt={item.node.title} />
                        }   
                      </a>
                    </li>
                      )
                })
            }
        </ul>
        </div>
    )
}
  
export default Designprojects   